import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Row, Col } from "components/layout"
import { Button } from "components/general"
import { Tooltip } from "@mui/material"
import { upgrade, claim } from "data/queries/tritium"
import { useQueryClient } from "react-query"
import UpIcon from "@mui/icons-material/ArrowCircleUp"
import styles from "./Miner.module.scss"

interface Props {
  building: Building
  build: Build
  setIsOpen: any
  planet: string
}

const Miner = (props: Props) => {
  const { t } = useTranslation()
  const { building, planet } = props
  const megaTrit = Number(building.level)
  const timestampInSeconds = Math.floor(Date.now() / 1000)
  const miningTime =
    timestampInSeconds - (building.start_tritium || timestampInSeconds)
  const speedMining = (megaTrit - 1) * 0.0001
  const [miningBalance, setMiningBalance] = useState<number>(
    miningTime * speedMining
  )
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const runTimer = () => {
    if (megaTrit > 1 && !isProcess) {
      setMiningBalance(miningBalance + speedMining)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      runTimer()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miningBalance])

  return (
    <div>
      <Row>
        <Col>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap"
            rel="stylesheet"
          ></link>
          <div>
            <span className={styles.level}>{Number(building.level)}</span>
            <span>{t("level")}</span>
          </div>
          <div style={{ display: "flex", padding: "0px", margin: "0px" }}>
            <div style={{ marginRight: "14px" }}>
              <img
                src={`/assets/builds/build-15.png`}
                alt={t("Shipyard")}
                className={styles.buildImage}
                draggable={false}
                style={{ width: "200px" }}
              />
              <div style={{ textAlign: "right" }}>
                <Tooltip title="Tritium">
                  <>
                    <img
                      src="/assets/icons/resources/tritium2.png"
                      alt="tritium"
                      style={{ width: "60px", marginRight: "2px" }}
                    />
                    <span className={styles.qtdResource}>5000</span>
                  </>
                </Tooltip>
                <Tooltip title="Crystal">
                  <>
                    <img
                      src="/assets/icons/resources/crystal.png"
                      alt="crystal"
                      style={{ width: "40px" }}
                    />
                    <span className={styles.qtdResourceCrystal}>9000</span>
                  </>
                </Tooltip>
              </div>
              <div>
                <Button
                  className={styles.upIcon}
                  onClick={() => {
                    setIsProcess(true)
                    setTimeout(() => {
                      setIsProcess(false)
                      queryClient.invalidateQueries()
                    }, 2000)
                    upgrade(planet, building.id || "0")
                  }}
                  size="small"
                  disabled={isProcess}
                >
                  {t("Upgrade")}
                  <UpIcon />
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className={styles.buildContent}>
            <div>{megaTrit <= 1 && "Upgrade to start miner"}</div>
            <div>{megaTrit >= 2 && "Mining..."}</div>
            <br />
            <br />
            {building.tritium && (
              <div className={styles.miningPanel}>
                {(Number(building.tritium || 0) + miningBalance)
                  .toFixed(4)
                  .toLocaleString()}
              </div>
            )}
            <div style={{ textAlign: "right" }}>
              <Button
                className={styles.upIcon}
                onClick={() => {
                  if (building.id) {
                    setIsProcess(true)
                    setTimeout(() => {
                      setIsProcess(false)
                      queryClient.invalidateQueries()
                      setMiningBalance(0.0)
                    }, 3000)
                    claim(building.id)
                  }
                }}
                disabled={miningBalance <= 0 || isProcess}
                size="small"
              >
                {t("Claim")}
                <UpIcon />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Miner
