import { TRITIUM } from "config/constants"
import api from "../services/api"

export const upgrade = (planetId: string, buildingId: string) => {
  return api.post<Return>(
    TRITIUM + `/api/tritium/upgrade/${planetId}/${buildingId}`
  )
}

export const claim = (buildingId: string) => {
  return api.post<Return>(TRITIUM + `/api/tritium/claim/${buildingId}`)
}
