import { TRITIUM } from "config/constants"
import api from "../services/api"

export const redeem = (code: string, wallet: string, planetId: string) => {
  return api.post<Return>(
    TRITIUM + `/api/shop/redeem/${code}/${wallet}/${planetId}`
  )
}

export const buy = (code: string, planetId: string) => {
  return api.post<Return>(TRITIUM + `/api/shop/buy/${code}/${planetId}`)
}

export const used = (wallet: string) => {
  return api.get(TRITIUM + `/api/shop/used/${wallet}`)
}
