import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { Tabs } from "components/layout"
import { Flex } from "components/layout"
import { useParams } from "react-router-dom"
import Miner from "./tritium/Miner"

interface Props {
  build: Build
  building: Building
  setIsOpen: any
}

const Tritium = (props: PropsWithChildren<Props>) => {
  const { build, building, setIsOpen } = props
  const { planet } = useParams()

  const { t } = useTranslation()

  const tabs = [
    {
      key: "miner",
      tab: t("Miner"),
      children: (
        <div style={{ height: "500px" }}>
          <Flex>
            <Miner
              building={building}
              build={build}
              setIsOpen={setIsOpen}
              planet={planet || ""}
            />
          </Flex>
        </div>
      ),
    },
  ]

  return (
    <div>
      <Tabs tabs={tabs} type="card" defaultActiveKey="miner" state={true} />
    </div>
  )
}

export default Tritium
