import { Table } from "components/layout"
import { useTranslation } from "react-i18next"
import styles from "./Ranking.module.scss"
import { getMyRanking } from "data/queries/ranking"
import { useEffect, useState } from "react"

interface Props {
  ranking: PlayerRanking[]
  currentUserId?: number
}

const TableScore = ({ ranking, currentUserId }: Props) => {
  const { t } = useTranslation()
  const planetId = localStorage.getItem("planet") ?? "0"
  const [myRanking, setMyRanking] = useState<PlayerRanking | null>(null)

  useEffect(() => {
    if (!myRanking) {
      getMyRanking(planetId).then((res) => setMyRanking(res.data))
    }
  }, [myRanking, planetId])

  const dataSource = () => {
    if (ranking.length === 0) {
      return [{} as PlayerRanking]
    }

    try {
      if (myRanking && myRanking.position && myRanking?.position > 4) {
        if (ranking.length > 3) {
          let top = ranking.slice(0, 3)
          top.push(myRanking)
          return top
        }
      }
      return ranking.slice(0, 4)
      // const userRankingIndex = ranking.findIndex((r) => r.id === currentUserId)
      // const userPosition = userRankingIndex + 1

      // if (userPosition <= 4 || userRankingIndex === -1) {
      //   return ranking.slice(0, 4)
      // } else {
      //   const topThree = ranking.slice(0, 3)
      //   const userRanking = ranking[userRankingIndex]
      //   return [...topThree, { ...userRanking, position: userPosition }]
      // }
    } catch (erro) {
      return [{} as PlayerRanking]
    }
  }

  return (
    <Table
      columns={[
        {
          key: "position",
          dataIndex: "position",
          align: "center",
          render: (number: number, record: PlayerRanking) => {
            if (ranking.length === 0) {
              return <div className={styles.loading}>{t("Loading...")}</div>
            }
            return (
              <>
                {record.player === myRanking?.player ? (
                  <div className={styles.boxDestak}>
                    <span className={styles.destak}>
                      {String(record.position).padStart(4, "0")}
                    </span>
                  </div>
                ) : (
                  <div className={styles.box}>
                    {String(record.position).padStart(4, "0")}
                  </div>
                )}
              </>
            )
          },
        },
        {
          key: "name",
          dataIndex: "name",
          align: "left",
          render: (name: string, record: PlayerRanking) => {
            if (ranking.length === 0) {
              return null
            }
            return record.player === myRanking?.player ? (
              <span className={styles.destak}>
                {name ? name.substring(0, 18) : ""}
              </span>
            ) : (
              <>{name ? name.substring(0, 18) : ""}</>
            )
          },
        },
        {
          key: "score",
          dataIndex: "score",
          align: "right",
          render: (score: number, record: PlayerRanking) => {
            if (ranking.length === 0) {
              return null
            }
            return record.player === myRanking?.player ? (
              <span className={styles.destak}> {score}</span>
            ) : (
              <>{score}</>
            )
          },
        },
      ]}
      dataSource={dataSource()}
      size="small"
    />
  )
}

export default TableScore
