import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { GameContext } from "pages/GameContext"
import { ScifiModal } from "components/feedback"
import Redeem from "components/shop/Redeem"
import Tooltip from "@mui/material/Tooltip"
import ArrowCircleUp from "@mui/icons-material/ArrowCircleUp"
import disableScroll from "disable-scroll"
import styles from "./Tritium.module.scss"
import Shop from "components/shop/Shop"
import { Button } from "components/general"

const TritiumDisplay = () => {
  const { t } = useTranslation()
  const { player } = useContext(GameContext)
  const [open, setOpen] = useState(false)
  const [redeemOpen, setRedeemOpen] = useState<boolean>(false)

  return (
    <>
      <div
        className={styles.mainBalance}
        onClick={() => {
          setOpen(true)
        }}
      >
        <img
          src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
          width={26}
          alt={t("TRITIUM")}
        />{" "}
        {player && player?.tritium.toLocaleString()}
      </div>
      <ScifiModal
        title={t("Intergalactic Shop")}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false)
          disableScroll.on()
        }}
      >
        <div className={styles.balance}>
          <img
            src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
            width={20}
            alt={t("TRITIUM")}
          />{" "}
          {player && player?.tritium.toLocaleString()}{" "}
          <span style={{ fontSize: "11px", color: "#fff" }}>TRIT</span>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title={"Redeem by NFT Gift Card"}>
            <Button
              onClick={() => {
                setRedeemOpen(true)
              }}
            >
              <ArrowCircleUp />
            </Button>
          </Tooltip>
        </div>
        <Shop />
      </ScifiModal>
      <Redeem redeemOpen={redeemOpen} setRedeemOpen={setRedeemOpen} />
    </>
  )
}

export default TritiumDisplay
