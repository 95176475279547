import { useState, useEffect } from "react"
import { PropsWithChildren } from "react"
import { useQueryClient } from "react-query"
import { ScifiModal } from "components/feedback"
import { used } from "data/queries/shop"
import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
} from "@terra-money/wallet-kit"
import { useConnectedWallet as useConnectedWalletGalaxy } from "@hexxagon/wallet-kit"
import styles from "./Shop.module.scss"
import { count } from "console"

interface Props {
  redeemOpen: any
  setRedeemOpen: any
}

const Redeem = (props: PropsWithChildren<Props>) => {
  const queryClient = useQueryClient()
  const { redeemOpen, setRedeemOpen } = props

  const { status } = useWallet()

  const wallet = useConnectedWallet()
  const address = wallet?.addresses["columbus-5"]

  const walletGalaxy = useConnectedWalletGalaxy()
  const addressGalaxy = walletGalaxy?.addresses["columbus-5"]

  const [cards, setCards] = useState<any>([])

  const nftList = async () => {
    try {
      address && setCards(used(address))
      addressGalaxy && setCards(used(addressGalaxy))
    } catch (error) {
      console.error("Erro ao buscar NFTs:", error)
    }
  }

  useEffect(() => {
    nftList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScifiModal
      title={"Redeem NFT gift card"}
      isOpen={redeemOpen}
      onRequestClose={() => {
        setRedeemOpen(false)
      }}
    >
      {status === WalletStatus.NOT_CONNECTED && (
        <div className={styles.msgConnect}>Please, connect wallet.</div>
      )}
      {status !== WalletStatus.NOT_CONNECTED && (
        <div className={styles.redeemBox}>
          {cards.length <= 0 && (
            <div className={styles.msgConnect}>NFT gift card not found.</div>
          )}
          {cards &&
            cards.length > 0 &&
            cards.forEach((element: any) => {
              ;<div>{element.token_id}</div>
            })}
        </div>
      )}
    </ScifiModal>
  )
}

export default Redeem
