import { PropsWithChildren, useState } from "react"
import { slotMap } from "data/queries/slotMap"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import Build from "./Build"
import BuildDetail from "./buildings/BuildDetail"
import BuildLabel from "./BuildLabel"
import BuildMenu from "./BuildMenu"
import UnderConstruction from "./UnderConstruction"
import styles from "./Slot.module.scss"
import { BuildDescriptionModal } from "./BuildDescriptionModal"

interface Props {
  space: Number
  planet: string
  buildings: Building[]
  constructs: Build[]
  onClick?: any
  onMouseDown?: any
  setIsDragable: any
  onClickX: number
  onClickY: number
}

const Construct = (props: PropsWithChildren<Props>) => {
  const {
    space,
    planet,
    buildings,
    constructs,
    onClick,
    onClickX,
    onClickY,
    setIsDragable,
  } = props

  const [onModalDetail, setOnModalDetail] = useState<boolean>(false)
  const [onCircular, setOnCircular] = useState<boolean>(false)
  const [buildHover, setBuildHover] = useState<boolean>(false)
  const [onDescriptionModal, setOnDescriptionModal] = useState<boolean>(false)

  const positionTop = slotMap[Number(space) - 1].top
  const positionLeft = slotMap[Number(space) - 1].left

  const queryClient = useQueryClient()

  function refreshModal(open: boolean) {
    setOnModalDetail(open)
    open ? setIsDragable(false) : setIsDragable(true)
  }

  function onConstruct(ready: Number | undefined) {
    if (new Date().getTime() < Number(ready) * 1000) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div>
        {buildings &&
          Array.isArray(buildings) &&
          buildings.length > 0 &&
          buildings.map((building) => (
            <div key={building.id}>
              {constructs &&
                constructs.map(
                  ({
                    id,
                    code,
                    name,
                    maxLevel,
                    description,
                    effect,
                    image,
                    metalStart,
                    uraniumStart,
                    crystalStart,
                    metalLevel,
                    uraniumLevel,
                    crystalLevel,
                    coefficient,
                  }) => (
                    <div key={id}>
                      {building.build === id && (
                        <>
                          {building.slot === space && (
                            <>
                              <div
                                className={styles.construct}
                                style={{
                                  position: "absolute",
                                  top: positionTop,
                                  left: positionLeft,
                                }}
                              >
                                <Build
                                  code={code}
                                  planet={planet}
                                  image={image}
                                  setOnModalDetail={setOnModalDetail}
                                  setIsDragable={setIsDragable}
                                  setOnCircular={setOnCircular}
                                  setBuildHover={setBuildHover}
                                  onCircular={onCircular}
                                  onClickX={onClickX}
                                  onClickY={onClickY}
                                  onConstruct={onConstruct(building.ready)}
                                />
                                <div draggable="true">
                                  <BuildDetail
                                    isOpen={onModalDetail}
                                    onClose={() => {
                                      queryClient.invalidateQueries(
                                        queryKey.tritium.building.buildings
                                      )
                                      setOnModalDetail(false)
                                      setIsDragable(true)
                                    }}
                                    setIsOpen={refreshModal}
                                    build={{
                                      id: id,
                                      code: code,
                                      name: name,
                                      maxLevel: maxLevel,
                                      description: description,
                                      effect: effect,
                                      image: image,
                                      metalStart: metalStart,
                                      uraniumStart: uraniumStart,
                                      crystalStart: crystalStart,
                                      metalLevel: metalLevel,
                                      uraniumLevel: uraniumLevel,
                                      crystalLevel: crystalLevel,
                                      coefficient: coefficient,
                                    }}
                                    building={{
                                      id: building.id,
                                      code: building.code,
                                      slot: building.slot,
                                      build: building.build,
                                      level: building.level,
                                      planet: planet,
                                      ready: Number(building.ready) * 1000,
                                      workers: building.workers,
                                      start_tritium: building.start_tritium,
                                      tritium: building.tritium,
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    top: code === 1 ? "-64px" : "-46px",
                                    left: "8px",
                                    display: buildHover ? "block" : "none",
                                  }}
                                >
                                  <BuildLabel
                                    title={name}
                                    level={Number(building.level)}
                                    onConstruction={onConstruct(building.ready)}
                                  />
                                </div>
                                {onCircular && (
                                  <BuildMenu
                                    code={0}
                                    setOnModalDetail={setOnModalDetail}
                                    setOnDescriptionModal={
                                      setOnDescriptionModal
                                    }
                                    setIsDragable={setIsDragable}
                                    setOnCircular={setOnCircular}
                                    building={building}
                                    onConstruction={onConstruct(building.ready)}
                                    build={{
                                      id: id,
                                      code: code,
                                      name: name,
                                      maxLevel: maxLevel,
                                      description: description,
                                      effect: effect,
                                      image: image,
                                      metalStart: metalStart,
                                      uraniumStart: uraniumStart,
                                      crystalStart: crystalStart,
                                      metalLevel: metalLevel,
                                      uraniumLevel: uraniumLevel,
                                      crystalLevel: crystalLevel,
                                      coefficient: coefficient,
                                    }}
                                  />
                                )}
                                {onConstruct(building.ready) && (
                                  <UnderConstruction
                                    ready={Number(building.ready)}
                                    image={image}
                                    code={code}
                                  />
                                )}
                              </div>
                              <BuildDescriptionModal
                                isOpen={onDescriptionModal}
                                onRequestClose={() => {
                                  setOnDescriptionModal(false)
                                  setIsDragable(true)
                                }}
                                build={{
                                  id: id,
                                  code: code,
                                  name: name,
                                  maxLevel: maxLevel,
                                  description: description,
                                  effect: effect,
                                  image: image,
                                  metalStart: metalStart,
                                  uraniumStart: uraniumStart,
                                  crystalStart: crystalStart,
                                  metalLevel: metalLevel,
                                  uraniumLevel: uraniumLevel,
                                  crystalLevel: crystalLevel,
                                  coefficient: coefficient,
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )
                )}
            </div>
          ))}
      </div>
      <div>
        {buildings.length === 0 && (
          <>
            {space === 11 && (
              <div
                className={`${styles.mainSlot} mainSlotTour`}
                onClick={onClick}
                style={{
                  position: "absolute",
                  top: positionTop,
                  left: positionLeft,
                }}
              />
            )}
            {space !== 11 && (
              <div
                className={styles.slot}
                style={{
                  position: "absolute",
                  top: positionTop,
                  left: positionLeft,
                }}
              />
            )}
            {space !== 11 && buildings.length === 0 && (
              <div
                className={styles.slotDisable}
                style={{
                  position: "absolute",
                  top: positionTop,
                  left: positionLeft,
                }}
              />
            )}
          </>
        )}
        {buildings.length > 0 && (
          <div
            className={styles.slot}
            onClick={onClick}
            style={{
              position: "absolute",
              top: positionTop,
              left: positionLeft,
            }}
          />
        )}
      </div>
    </>
  )
}

export default Construct
