import { TRITIUM } from "config/constants"
import api from "../services/api"

export const claimReward = (code: string, wallet: string, planetId: string) => {
  return api.post<Return>(
    TRITIUM + `/api/reward/claim/${code}/${wallet}/${planetId}`
  )
}

export const claimVerify = (code: string) => {
  return api.get(TRITIUM + `/api/reward/verify/${code}`)
}
