import { usePlanets } from "data/queries/planet"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import styles from "./PlanetList.module.scss"
import { tour } from "pages/TourGuide"
import { useEffect } from "react"
// import { useQuadrant } from "data/queries/quadrant"
// import { useParams } from "react-router-dom"

interface Props {
  planet: number
  open: boolean
  setOpen: any
  resetSelect?: any
}

const PlanetList = (props: Props) => {
  const { open, setOpen, resetSelect } = props

  const planets = usePlanets()

  //const { code } = useParams()
  //const quadrant = useQuadrant(code || "A000")

  const quadrant = Number(localStorage.getItem("quadrant")?.slice(-3))

  useEffect(() => {
    const onTourFinish = () => {
      localStorage.setItem("hasCompletedTour", "true")
    }

    tour.on("cancel", onTourFinish)
    tour.on("complete", onTourFinish)

    const hasCompletedTour = localStorage.getItem("hasCompletedTour") === "true"
    if (!hasCompletedTour) {
      tour.start()
    }

    return () => {
      tour.off("cancel", onTourFinish)
      tour.off("complete", onTourFinish)
    }
  }, [])

  return (
    <>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            position: "absolute",
            left: "calc(50% - 100px)",
            top: "22px",
            height: "610px",
            width: "200px",
            background: "transparent",
            clipPath: `polygon(
              0 0,
              0 97%,
              10% 100%,
              90% 100%,
              100% 97%,
              100% 0
            )`,
          },
        }}
        elevation={0}
        anchor="top"
        open={open}
        onClose={() => {
          setOpen(false)
          if (resetSelect) {
            resetSelect(null)
          }
        }}
        onOpen={() => {}}
      >
        <div className={styles.content}>
          {planets.data &&
            Array.isArray(planets.data) &&
            planets.data.map((planet: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={styles.planet}
                  onClick={() => {
                    localStorage.setItem("planet", planet.id)
                    localStorage.setItem("planetName", planet.name)
                    localStorage.setItem("planetQuadrant", planet.quadrant)
                    localStorage.setItem("planetId", planet.id)
                    localStorage.setItem("quadrant", planet.quadrant)
                    localStorage.setItem("quadrantName", planet.quadrantName)
                    setOpen(false)
                    window.location.href = `/game/planet/${planet.id}`
                  }}
                >
                  <img
                    src={
                      Number(quadrant) % 4 === 0
                        ? "/assets/planets/planetDesert.png"
                        : Number(quadrant) % 4 === 1
                        ? "/assets/planets/planetGrass.png"
                        : Number(quadrant) % 4 === 2
                        ? "/assets/planets/planetIce.png"
                        : "/assets/planets/planetVulcan.png"
                    }
                    alt={planet.name}
                    draggable={false}
                  />
                  <div className={styles.name}>{planet.name}</div>
                  <div className={styles.quadrant}>{planet.quadrant}</div>
                </div>
              )
            })}
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default PlanetList
