import { Col, Row } from "components/layout"
import { useState } from "react"
import { PropsWithChildren } from "react"
import Buy from "./Buy"
import disableScroll from "disable-scroll"
import styles from "./Shop.module.scss"

interface Props {}

const Shop = (props: PropsWithChildren<Props>) => {
  const [msg, setMsg] = useState<string>("")

  const imageSize = 118

  return (
    <>
      <div className={styles.message}>{msg}</div>
      <hr />
      <Row>
        <Col>
          <div
            className={styles.cardsBox}
            onWheel={(e) => {
              disableScroll.off()
            }}
          >
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Energy Bank (50k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/energy.png"
                      alt="Energy"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>2000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="E001" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Energy Bank (300k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/energy.png"
                      alt="Energy"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>10000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="E002" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Humanoid Builder (5)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/population.png"
                      alt="Population"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>5000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="H001" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Metal (25k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/metal.png"
                      alt="Metal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>1000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="M001" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Metal (100k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/metal.png"
                      alt="Metal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>3000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="M002" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Metal (250k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/metal.png"
                      alt="Metal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>7000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="M003" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Uranium (25k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/uranium.png"
                      alt="Uranium"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>2000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="U001" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Uranium (100k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/uranium.png"
                      alt="Uranium"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>6000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="U002" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Uraium (250k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/uranium.png"
                      alt="Uranium"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>14000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="U003" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Crystal (25k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/crystal.png"
                      alt="Crystal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>3000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="C001" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Crystal (100k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/crystal.png"
                      alt="Crystal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>9000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="C002" />
                </Col>
              </Row>
            </div>
            <div className={styles.card}>
              <Row>
                <Col>
                  <div className={styles.title}>Crystal (250k)</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <img
                      width={imageSize}
                      src="/assets/icons/resources/crystal.png"
                      alt="Crystal"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.price}>21000 TRIT</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Buy setMsg={setMsg} itemCode="C003" />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div>
            <div></div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Shop
