export function nftNameToCode(nftName: string) {
  const nftArr = nftName.split("#")

  if (nftArr[0] != "TT_ORIGINS_ASSET_") {
    return 0
  }

  const id = Number(nftArr[1])

  if (id === undefined) {
    return 1
  }

  if (id <= 150) {
    return "founderEpic"
  }
  if (id <= 250) {
    return "founderLegendary"
  }
  if (id <= 350) {
    return "key"
  }

  if (id > 350) {
    return 2
  }
}
