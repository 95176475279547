import { useParams } from "react-router-dom"
import { PropsWithChildren } from "react"
import { ScifiButton } from "components/general"
import { buy } from "data/queries/shop"
import { useQueryClient } from "react-query"
import styles from "./Shop.module.scss"

interface Props {
  setMsg: any
  itemCode: string
}

const Buy = (props: PropsWithChildren<Props>) => {
  const { planet } = useParams()
  const queryClient = useQueryClient()
  const { setMsg, itemCode } = props

  return (
    <div className={styles.buy}>
      <ScifiButton
        onClick={() => {
          planet && buy(itemCode, planet)
          setMsg("Process...")
          setTimeout(() => {
            setMsg("Purchase completed successfully!")
            queryClient.invalidateQueries()
          }, 2000)
        }}
      >
        Buy
      </ScifiButton>
    </div>
  )
}

export default Buy
