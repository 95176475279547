import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ScifiModal } from "components/feedback"
import { Row, Col } from "components/layout"
import { claimReward, claimVerify } from "data/queries/reward"
import { TRITIUM_NFT_ORIGINS } from "config/constants"
import FounderLegendaryImage from "styles/nft/founder1.png"
import FounderEpicAnime from "styles/nft/founder2.webp"
import FounderEpicImage from "styles/nft/founder2.png"
import { useParams } from "react-router-dom"
import keyImage from "styles/nft/key.png"
import styles from "./Founder.module.scss"
import { ScifiButton } from "components/general"
import { nftNameToCode } from "data/nftAssets"
import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
  useLcdClient,
} from "@terra-money/wallet-kit"
import {
  useConnectedWallet as useConnectedWalletGalaxy,
  useWallet as useWalletGalaxy,
  WalletStatus as WalletStatusGalaxy,
  useLcdClient as useLcdClientGalaxy,
} from "@hexxagon/wallet-kit"

const Founder = () => {
  const { t } = useTranslation()

  interface NFTToken {
    name: string
  }

  const { planet } = useParams()

  const { status } = useWallet()
  const { status: statusGalaxy } = useWalletGalaxy()
  const lcd = useLcdClient()
  const lcdGalaxy = useLcdClientGalaxy()

  const [onSelect, setOnSelect] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [alreadyClaim, setAlreadyClaim] = useState<any>(false)
  const [labelBntClaim, setLabelBtnClaim] = useState<string>("claim")

  const [display, setDisplayShow] = useState<boolean>(false)
  const [founderEpicPresent, setFounderEpicPresent] = useState<boolean>(false)
  const [founderLegendaryPresent, setFounderLegendaryPresent] =
    useState<boolean>(false)
  const [keyPresent, setKeyPresent] = useState<boolean>(false)

  const wallet = useConnectedWallet()
  const address = wallet?.addresses["columbus-5"]

  const walletGalaxy = useConnectedWalletGalaxy()
  const addressGalaxy = walletGalaxy?.addresses["columbus-5"]

  const lcdGalaxyRef = useRef(lcdGalaxy)

  const fetchNFTs = async () => {
    let lastTokenSequence = 0
    let allTokens = []
    let moreTokensAvailable = true

    try {
      while (moreTokensAvailable) {
        const data: any =
          statusGalaxy === WalletStatusGalaxy.CONNECTED && addressGalaxy
            ? await lcdGalaxyRef.current.wasm.contractQuery(
                TRITIUM_NFT_ORIGINS,
                {
                  tokens_with_info: { owner: addressGalaxy },
                }
              )
            : await lcd.wasm.contractQuery(TRITIUM_NFT_ORIGINS, {
                tokens_with_info: {
                  owner: address,
                  start_after: lastTokenSequence,
                },
              })

        if (data && data.tokens) {
          allTokens.push(...data.tokens)
          lastTokenSequence += data.tokens.length - 1
          moreTokensAvailable = data.tokens.length >= 20
        }
      }

      moreTokensAvailable = true

      setFounderEpicPresent(existNFT(allTokens, "founderEpic"))
      setFounderLegendaryPresent(existNFT(allTokens, "founderLegendary"))
      setKeyPresent(existNFT(allTokens, "key"))

      const cVerify: any = await claimVerify("A002")

      setAlreadyClaim(cVerify.data)
    } catch (error) {
      console.error("Erro ao buscar NFTs:", error)
    }
  }

  const existNFT = (tokens: any, type: string): boolean => {
    let result = false

    tokens.forEach((e: NFTToken) => {
      if (nftNameToCode(e.name) == type) {
        result = true
      }
    })

    return result
  }

  return (
    <>
      {status === WalletStatus.NOT_CONNECTED && (
        <>
          {display && (
            <div className={styles.title_slot_div}>
              {t("Reserved for NFT founders")}
            </div>
          )}
          <div
            className={styles.title_slot}
            onMouseOver={() => setDisplayShow(true)}
            onMouseOut={() => setDisplayShow(false)}
          ></div>
        </>
      )}
      {status !== WalletStatus.NOT_CONNECTED && (
        <>
          <div
            className={styles.founder}
            onMouseOver={() => setOnSelect(true)}
            onMouseOut={() => setOnSelect(false)}
            onClick={() => {
              setOpen(true)

              if (
                (statusGalaxy === WalletStatusGalaxy.CONNECTED &&
                  addressGalaxy) ||
                (status === WalletStatus.CONNECTED && address)
              ) {
                fetchNFTs()
              }
            }}
          >
            <img
              src={FounderEpicAnime}
              width={250}
              alt={t("Founder")}
              draggable={false}
            />
          </div>
          <div className={onSelect ? styles.label : styles.hiden}>
            {t("Founder")}
          </div>
          <ScifiModal
            title={t("Founders' Monument")}
            isOpen={open}
            onRequestClose={() => {
              setOpen(false)
            }}
          >
            <div className={styles.modalContent}>
              <div>
                <Row>
                  <Col>
                    {founderLegendaryPresent && (
                      <img
                        src={FounderLegendaryImage}
                        alt={t("Founder")}
                        width={300}
                        draggable={false}
                      />
                    )}
                    {founderEpicPresent && !founderLegendaryPresent && (
                      <img
                        src={FounderEpicImage}
                        alt={t("Founder")}
                        width={300}
                        draggable={false}
                      />
                    )}
                    {!founderEpicPresent && !founderLegendaryPresent && (
                      <div className={styles.box}>
                        Searching for NFT Founder ...
                      </div>
                    )}
                  </Col>
                  <Col>
                    <div className={styles.box}>
                      <b>
                        {t(
                          "Monument of the Founders – The Key to Legacy and Glory"
                        )}
                      </b>
                      <br />
                      <br />
                      {t(
                        "The Terra Tritium Founders' Monument stands as a testament to the pioneers who embarked on this thrilling journey from the very beginning. But this monument holds more than just history—it guards a powerful secret for whom holds The Key. Hidden within its ancient structure, carved from pure tritium and inscribed with mysterious symbols, the Founder’s Key is a relic of immense value."
                      )}
                      <br />
                      <br />
                      {!keyPresent && <div>Searching for NFT Red Key...</div>}
                      {keyPresent && (
                        <div>
                          <div>NFT Red key connected!</div>
                          <br />
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={keyImage}
                              width={60}
                              alt={t("NFT Key")}
                              draggable={false}
                            />
                          </div>
                          <br />
                          <br />
                          {t("Will you claim its power?")}
                          <br />
                          <br />
                          <table className={styles.rewardTable}>
                            <tr>
                              <td>10k Research Points</td>
                              <td>
                                {alreadyClaim == "false" && (
                                  <ScifiButton
                                    onClick={() => {
                                      setLabelBtnClaim("process...")
                                      if (address && planet) {
                                        claimReward("A002", address, planet)
                                      }
                                      if (addressGalaxy && planet) {
                                        claimReward(
                                          "A002",
                                          addressGalaxy,
                                          planet
                                        )
                                      }
                                      setTimeout(() => {
                                        setLabelBtnClaim("used")
                                      }, 2000)
                                    }}
                                  >
                                    {labelBntClaim}
                                  </ScifiButton>
                                )}
                                {alreadyClaim == "true" && (
                                  <ScifiButton disabled>used</ScifiButton>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>100k Metal</td>
                              <td>
                                <ScifiButton disabled onClick={() => {}}>
                                  soon
                                </ScifiButton>
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ScifiModal>
        </>
      )}
    </>
  )
}

export default Founder
